<template>
  <div>
    <navbar />
    <mice-cloud-image />
    <mice-cloud-presentation class="mt100" />
    <mice-cloud-fonctionnalites class="mt100" />
    <mice-cloud-video class="mt100" />

    <foot class="mt100" />
  </div>
</template>

<script>
import foot from "@/components/FooterBis.vue";
import navbar from "@/components/NavBar.vue";
import MiceCloudPresentation from "../../components/PageMicecloud/MiceCloudPresentation.vue";
import MiceCloudImage from "../../components/PageMicecloud/MiceCloudImage.vue";
import MiceCloudFonctionnalites from "../../components/PageMicecloud/MiceCloudFonctionnalites.vue";
import MiceCloudVideo from "../../components/PageMicecloud/MiceCloudVideo.vue";

export default {
  components: {
    foot,
    navbar,
    MiceCloudImage,
    MiceCloudPresentation,
    MiceCloudFonctionnalites,
    MiceCloudVideo,
  },
  metaInfo() {
    return {
      title: this.$t("pageMiceCloud.metaTitle"),
      link: [
        {
          rel: "canonical",
          href: `https://micetracking.com${this.$route.path}`,
        },
      ],
      meta: [
        {
          name: "description",
          content: this.$t("pageMiceCloud.metaDescription"),
        },
        {
          name: "keywords",
          content: this.$t("pageMiceCloud.metaKeywords"),
        },
      ],
    };
  },
};
</script>
